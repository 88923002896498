import React from "react"
import { graphql } from "gatsby"
import "./base-styles.scss"
import "./LayoutCommon"
import { baseClass, childClass } from "./LayoutCommon";
import SiteHeader from "components/headers/SiteHeader";
import { Mdx, MdxConnection } from "@GraphQLModels";
import MdxBlock from "components/texty/MdxBlock";
import { PedalDataContext, PedalDataContextProps } from "@Contexts/PedalDataContext";
import { Pedal, BuyLinkCollection, ArticleMetaData } from "@ViewModels";
import { HomePageCarousel, HomePageCarouselSlide } from "components/explorers/HomePageCarousel"
import InlineBuyLinks from "components/explorers/InlineBuyLinks"
import SiteFooter from "components/footer/SiteFooter"
import FixedHeightImage from "components/media/FixedHeightImage";
import PageMeta from "components/texty/PageMeta";
import ArticleLinkCard from "components/cards/ArticleLinkCard";


interface IProps {
  pageContext: {
    id: string,
    buyPedalLinks: string[],
    carouselArticleRoute: string,
    inlineArticleRoute: string
  };
  data: {
    mdx: Mdx,
    inlineArticle: Mdx,
    carouselArticle: Mdx,
    buyLinkPedals: MdxConnection
  }
}

export default function HomePage(props: IProps) {

  const buyLinkPedals = props.data.buyLinkPedals ? props.data.buyLinkPedals.edges.map(x => Pedal.fromMdxNode(x.node)) : [];
  const inlineArticleBuyLinkArray = (props.data.inlineArticle.frontmatter.buyLinks || []).map(link => BuyLinkCollection.fromMdxMeta(link, buyLinkPedals));
  const trendingBuyLinks = BuyLinkCollection.fromIdListString('Popular Pedals', props.data.mdx.frontmatter.trendingPedals, buyLinkPedals);
  const pedalData: PedalDataContextProps = {
    buyLinks: Object.assign({
      trending: trendingBuyLinks
    }, ...inlineArticleBuyLinkArray.map((item, index) => ({
      [`article-${index + 1}`]: item
    })))
  };

  return (
    <div className={baseClass}>
      <PageMeta
        title='Pedal Dudes'
        route='/'>
      </PageMeta>
      <SiteHeader />
      <div className={childClass('dark-bg-block')}>
        <div className={childClass('container', 'full-width-mobile')}>
          <HomePageCarousel>
            <HomePageCarouselSlide
              imagePath='/assets/media/generic-pedals-2.jpg'
              title='Browse Effects'
              subTitle='Look through our database of over 700 pedals'
              ctaText='Browse'
              linkRoute='browse-pedals' />
            <HomePageCarouselSlide
              imagePath={props.data.carouselArticle.frontmatter.mainImage}
              title={props.data.carouselArticle.frontmatter.title}
              subTitle={props.data.carouselArticle.frontmatter.subTitle}
              linkRoute={props.data.carouselArticle.fields.route} />
            <HomePageCarouselSlide
              imagePath='/assets/media/tonebender-review-still.jpg'
              title='Pedal Review'
              subTitle='Colorsound: Yellow Hybrid Tone Bender Fuzz'
              ctaText='Watch'
              linkRoute='https://www.youtube.com/watch?v=lQY5-ppgUs0' />
            <HomePageCarouselSlide
              imagePath='/assets/media/mad-prof-supreme.jpg'
              title='Spotlight On'
              subTitle='Mad Professor Supreme Overdrive'
              linkRoute='mad-professor/supreme/' />
          </HomePageCarousel>
        </div>
      </div>
      <PedalDataContext.Provider value={pedalData}>
        <MdxBlock mdx={props.data.mdx} className={childClass('container')} />
        <InlineBuyLinks listKey={'trending'} className={childClass('container')} />
        <div className={[childClass('full-width'), childClass('content-seperator')].join(' ')}>
          <h2 className={childClass('content-seperator-title')}>{props.data.inlineArticle.frontmatter.title}</h2>
        </div>
        <FixedHeightImage className={[childClass('container'), childClass('big-row', 'lg-screens-only')].join(' ')}
          src={props.data.inlineArticle.frontmatter.mainImage}
          height='400px' />
        <MdxBlock mdx={props.data.inlineArticle} className={[childClass('container'), childClass('big-row')].join(' ')} showTitle={false} />
        <div className={[childClass('container'), childClass('big-row')].join(' ')}>
          <h3>Read Next</h3>
          <ArticleLinkCard articleMeta={ArticleMetaData.from(props.data.carouselArticle)} className={childClass('row')} inPanel={false} />
        </div>
        <SiteFooter />
      </PedalDataContext.Provider>
    </div>
  )
}

/**
 * Query for data for the page. Note that $id is injected in via context from
 * actions.createPage. See gatsby-node.js for more info.
 */
export const pageQuery = graphql`
  query HomePageQuery($inlineArticleRoute: String, $carouselArticleRoute: String, $buyPedalLinks: [String]) {
        mdx(fields: {route: {eq: "/home-page/" } }) {
        id
        frontmatter {
          title
          mainImage
          trendingPedals
          inlineArticle
          carouselArticle
        }
        code {
        body
      }
      }
      inlineArticle: mdx(fields: {route: {eq: $inlineArticleRoute } }) {
        id
        fields {
          route
        }
        frontmatter {
          title
          mainImage
          buyLinks {
            title
            idList
          }
        }
        code {
          body
        }
      }
      carouselArticle: mdx(fields: {route: {eq: $carouselArticleRoute } }) {
        id
        fields {
          route
        }
        frontmatter {
          title
          mainImage
          date
          standfirst
        }
        code {
          body
        }
      }      
      buyLinkPedals: allMdx( filter: {fields: {route : { in: $buyPedalLinks } } } ) {
        edges {
        node {
        fields {
        route
      }
      frontmatter {
        title
      }
      }
    }
  }
}
`
