import * as React from "react";
import { childClassClosure, baseClassAnd } from "@Functions";
import Siema from 'siema';
import bind from "bind-decorator";
import "./HomePageCarousel.scss";
import { TriangleSvg, ArrowSvg } from "@Svg";
import LinkWrapper from "components/texty/LinkWrapper";

const baseClass = 'home-page-carousel';
const childClass = childClassClosure(baseClass);

interface ICarouselProps {
  className?: string
  children: JSX.Element[] | JSX.Element;
}

interface ISlideProps {
  title: string,
  subTitle: string,
  linkRoute: string,
  imagePath: string,
  ctaText?: string
}

export class HomePageCarouselSlide extends React.Component<ISlideProps, {}> {
  constructor(props: ISlideProps) {
    super(props)
  }

  @bind
  captureLinkClick(event: React.MouseEvent<HTMLAnchorElement>) {
    window.location.href = event.currentTarget.href;
  }

  render() {
    return <div className={childClass('slide')} style={{ backgroundImage: `url('${this.props.imagePath}')` }}>
      <div className={childClass('text-container')}>
        <LinkWrapper className={childClass('text-container-title')} to={this.props.linkRoute} onMouseDown={this.captureLinkClick} onClick={this.captureLinkClick}>{this.props.title}</LinkWrapper>
        <h5 className={childClass('sub-title')}>{this.props.subTitle}</h5>
        {this.props.ctaText && <div className={childClass('link-button')}>
          <LinkWrapper className={childClass('link-button-anchor')}
            to={this.props.linkRoute}>
            <span className={childClass('link-button-label')}>{this.props.ctaText}</span>
            <ArrowSvg className={childClass('link-button-arrow')} />
          </LinkWrapper>
        </div>}
      </div>
    </div>
  }
}

export class HomePageCarousel extends React.Component<ICarouselProps, {}> {

  constructor(props: ICarouselProps) {
    super(props);
    this.siemaRef = React.createRef();
  }

  siema?: Siema;
  siemaRef: React.RefObject<HTMLElement>;
  autoPlayTimer?: any;

  @bind
  prev() {
    this.siema.prev()
  };

  @bind
  next() {
    this.siema.next()
  }

  componentDidMount() {
    const onInitFn = this.stopAutoSliding;
    this.siema = new Siema({
      duration: 500,
      easing: 'ease-out',
      draggable: true,
      loop: true,
      onInit: () => {
        this.siemaRef!.current.addEventListener('mousedown', onInitFn);
        this.autoPlayTimer = setInterval(() => {
          this.siema.next();
        }, 4000);
      }
    });
  }

  @bind
  stopAutoSliding() {
    clearInterval(this.autoPlayTimer);
  }

  componentWillUnmount() {
    clearInterval(this.autoPlayTimer);
  }

  render() {
    return (
      <div className={baseClassAnd(baseClass, this.props)}>
        <button className={childClass('btn-prev')} onClick={this.prev}><TriangleSvg /></button>
        <nav className={`${childClass('widget')} siema`} ref={this.siemaRef}>
          {this.props.children}
        </nav>
        <button className={childClass('btn-next')} onClick={this.next}><TriangleSvg /></button>
      </div>
    );
  }
}

